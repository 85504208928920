$(function () {
  svg4everybody();
  
  // slider
  var slider = $('.js-slider');
  slider.owlCarousel({
    items: 1,
    nav: false,
    dots: true,
    dotsEach: true,
    loop: true,
    margin: 0,
    smartSpeed: 550,
    autoplay: true,
    autoplayTimeout: 4000,
    autoplayHoverPause: true,
  });

  // slider
  var objects = $('.js-objects-slider');
  objects.owlCarousel({
    items: 3,
    nav: true,
    dots: false,
    dotsEach: true,
    loop: true,
    margin: 30,
    smartSpeed: 550,
    autoplay: true,
    autoplayTimeout: 4000,
    autoplayHoverPause: true,
    responsive:{
      0:{
        items:1,
      },
      480:{
        items:2,
      },
      768:{
        items:2,
      },
      992:{
        items: 3,
      },
      1199:{
        items: 3,
      },
    }
  });

  $('.owl-item [data-fancybox]').fancybox({
    infobar: false,
    idleTime: false,
    loop: true,
    buttons: [
      "zoom",
      "close",
    ],
  });

  // gallery 
  var fancyboxImages = {
    'gallery1' : [
      {
        type: 'image',
        src : 'https://source.unsplash.com/eWFdaPRFjwE/1500x1000',
        thumb : 'https://source.unsplash.com/eWFdaPRFjwE/240x160',
        caption : 'Mallorca, Llubí, Spain'
      },
      {
        type: 'image',
        src : 'https://source.unsplash.com/c1JxO-uAZd0/1500x1000',
        thumb : 'https://source.unsplash.com/c1JxO-uAZd0/240x160',
      },
      {
        type: 'image',
        src : 'https://source.unsplash.com/eXHeq48Z-Q4/1500x1000',
        thumb : 'https://source.unsplash.com/eXHeq48Z-Q4/240x160',
      },
      {
        type: 'image',
        src : 'https://source.unsplash.com/RFgO9B_OR4g/1500x1000',
        thumb : 'https://source.unsplash.com/RFgO9B_OR4g/240x160',
      }
    ],
    'gallery2' : [
      {
        type: 'image',
        src : 'https://source.unsplash.com/NhU0nUR7920/1500x1000',
        thumb : 'https://source.unsplash.com/NhU0nUR7920/240x160',
      },
      {
        type: 'image',
        src : 'https://source.unsplash.com/B2LYYV9-y0s/1500x1000',
        thumb : 'https://source.unsplash.com/B2LYYV9-y0s/240x160',
      }
    ]
  };
  
  $('.js-gallery [data-fancybox]').fancybox({
    thumbs: {
      autoStart: true, 
    },
    onInit : function(instance) {
      var firstLink = instance.group[instance.currIndex].opts.$orig;
  
      instance.addContent( fancyboxImages[ firstLink.data('fancybox') ] );
    }
  });

  // tabs
  $('.js-tabs-title a').click(function(event){
    event.preventDefault();
    var el = $(this).attr('href');
    $('.js-tabs-title a').removeClass('active');
    $(this).addClass('active');
    $('.js-tab').removeClass('active');
    $(el).addClass('active');
    return false; 
  });
  
  // mask
  $('.js-tel, input[type="tel"]').mask('+7 (000) 000-00-00');

  // загрузка файла в форму
  $('.js-file').change(function(event) {
    var name = this.files[0].name;
    $('.js-file-link').text(name);
  });

  // animate
  AOS.init();

  // up
  $(window).scroll(function () {
    if ($ (this).scrollTop () > 100) {
      $ ('.js-up').fadeIn();
    } else {
      $ ('.js-up').fadeOut();
    }
  });
  $('.js-up').click(function(){
    $('body,html').animate({
      scrollTop: 0
    }, 500);
    return false;
  });

  // popup
  $('.js-order-link').on('click', function(){
    $('.js-popup-bg').addClass('open');
    $('.js-popup').fadeIn();
  });
  $('.js-popup__close').on('click', function(){
    $('.js-popup-bg').removeClass('open');
    $('.js-popup').fadeOut();
  });

  // клик в свободной зоне
  $('.js-popup-bg').click( function(event){
    if( $(event.target).closest(".js-popup").length )
      return;
    $('.js-popup__close').trigger('click');
    event.stopPropagation();
  });
});
